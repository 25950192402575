/* Login signup Page Start */
.login-container {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.login-container.register-screen .right-side {
    max-height: 100vh;
    overflow-y: scroll;
}

.login-container.register-screen .right-side::-webkit-scrollbar {
    width: 4px;
}

.login-container.register-screen .right-side::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

.login-container.register-screen .right-side::-webkit-scrollbar-thumb {
    background-color: var(--clr-dark-blue);
    outline: 1px solid var(--clr-dark-blue);
    border-radius: 15px;
}

.login-container .left-side,
.login-container .right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container.register-screen .right-side {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.login-container .right-side .btn-primary {
    width: 100%;
}

.login-container .left-side {
    background-color: #f8f9fa;
    padding: 20px;
    background-image: url('../login-bg-img.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.center-card {
    background: rgba(255, 242, 242, 0.13);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 350px;
    text-align: center;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
}

.login-container .center-card p {
    color: var(--clr-white);
    font-size: var(--normal-font-size);
}

.login-container .right-side {
    background-color: #ffffff;
    padding: 20px;
}

.login-container .right-side .form-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.login-container .right-side .form-contents .logo-div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    align-self: center;
}

.login-container .login-form {
    width: 100%;
    max-width: 400px;
    text-align: left;
}

.right-side .form-contents .logo {
    max-width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 1rem;
}

.right-side .form-contents h2 {
    margin-bottom: .5rem;
    font-size: var(--h2-font-size);
}

.right-side .form-contents p {
    margin-bottom: 25px;
    font-size: var(--normal-font-size);
}

.right-side .form-contents .auth-form {
    width: 100%;
}

.right-side .form-contents .auth-form .other-links {
    margin: 1.5rem 0;
}

.right-side .form-contents .auth-form .other-links a {
    color: red;
    text-align: end;
}

.right-side .remember-links {
    margin-top: 1rem;
    text-align: center;
}

.right-side .remember-links a {
    color: var(--clr-green);
    font-size: var(--normal-font-size);
}

.otp-screen .right-side .form-contents {
    align-items: center;
    justify-content: center;
}

.otp-screen .right-side .title-contents {
    text-align: center;
}

.login-container .btn-primary {
    margin-top: 2rem;
}

@media (max-width: 1024px) {
    .login-container .width-80 {
        width: 90%;
    }

}

@media (max-width: 900px) {
    .login-container .left-side {
        flex: 1;
    }

    .login-container .right-side {
        flex: 2;
    }

}

@media (max-width: 768px) {
    .login-container .left-side {
        display: none;
    }

    .login-container .right-side {
        flex: 1;
        background-image: url('../login-bg-img.jpg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        backdrop-filter: blur(8px);
    }

    .login-container .right-side .contact-us-form-50-50 {
        flex-direction: row;
    }

}

@media (max-width: 475px) {

    .login-container .right-side {
        padding: 0px;
    }

    .login-container .width-80 {
        width: 98%;
    }
}



/* Shared */
.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #FFF;
}
.loginBtn:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0,0,0,0.1);
}


/* Facebook */
.loginBtn--facebook {
  background-color: #4C69BA;
  background-image: linear-gradient(#4C69BA, #3B55A0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354C8C;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5B7BD5;
  background-image: linear-gradient(#5B7BD5, #4864B1);
}


/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #DD4B39;
}
.loginBtn--google:before {
  border-right: #BB3F30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #E74B37;
}