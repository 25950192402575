@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

:root {
  --header-height: 4rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/

  --clr-dark-blue: #012541;
  --clr-light-blue: #1695d1;
  --clr-green: #83b42c;
  --clr-dark-green: #4d871b;
  --clr-orange: #f6a834;
  --clr-white: #ffffff;
  --clr-light-grey: #eeeeee;
  --clr-medium-grey: #828c8a;
  --clr-dark-grey: #313e3b;
  --border: 1px solid var(--clr-light-grey);
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --background-image: linear-gradient(to bottom,
      #ffffff,
      #f7f9f9,
      #f3f6f6,
      #eff3f3,
      #ecf1f1);

  /*========== Font and typography ==========*/
  --body-font: "Space Grotesk", sans-serif;

  --big-font-size: 5rem;
  --h1-font-size: 3.87rem;
  --h2-font-size: 2rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 1.2rem;
  --normal-font-size: 1.15rem;
  --small-font-size: 0.813rem;

  /*========== Font weight ==========*/
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media (max-width: 1280px) {
  :root {
    --big-font-size: 4rem;
    --h1-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.5rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: 1.15rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 1020px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 3rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1.05rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 900px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 3rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1.15rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 475px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.8rem;
  }
}

@media (max-width: 390px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.5rem;
  }
}

/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter", sans-serif; */
  font-family: "Space Grotesk", sans-serif;
}

html {
  scroll-behavior: smooth;
}

input,
button,
body {
  font-family: var(--body-font);
  line-height: 30px;
}

body {
  background-color: var(--clr-white);
  color: var(--clr-dark-blue);
  transition: background 0.4s;
  /* for dark mode animation */
  font-family: inherit;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--clr-dark-blue);
  outline: 1px solid var(--clr-dark-blue);
  border-radius: 15px;
}

input,
button {
  border: none;
  outline: none;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-weight-700);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.grid {
  display: grid;
  gap: 1.5rem;
}

.flex {
  display: flex;
  gap: 1.5rem;
}

.section {
  padding-block: 5rem;
}

.main {
  overflow: hidden;
  /* For animation ScrollReveal */
}

/* Global CSS */
.green {
  color: var(--clr-green);
}

.bold {
  font-weight: var(--font-weight-700);
}

.marquee-large-text {
  font-size: 10rem;
  color: var(--clr-light-grey);
  opacity: 0.7;
}

.btn {
  display: flex;
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
  text-align: center;
  padding: 0.8rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 35px;
  width: fit-content;
  justify-content: center;
}

.btn i {
  margin-left: 0.2rem;
}

.btn-primary {
  transition: 0.5s;
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
}

.btn-primary:hover {
  transition: 0.5s;
  background-color: var(--clr-green);
  color: var(--clr-white);
}

.btn-green {
  transition: 0.5s;
  background-color: var(--clr-green);
  color: var(--clr-white) !important;
}

.btn-green:hover {
  transition: 0.5s;
  background-color: var(--clr-white);
  color: var(--clr-dark-blue) !important;
}

.shadow-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.25);
}

.section-title {
  padding-block: 3rem;
  text-align: center;
  font-size: 40px;
  position: relative;
  margin: 0 0 15px;
  z-index: 1;
}

.section-title span {
  font-size: inherit;
  background: linear-gradient(105.55deg,
      var(--clr-dark-blue) -6.68%,
      var(--clr-light-blue) 43.13%,
      var(--clr-green) 96.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title span::after {
  content: "";
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  left: 0;
  height: 70px;
  bottom: 0;
  z-index: -1;
  width: 100%;
  top: 15px;
  opacity: 0.1;
}

.separation-line {
  background-color: var(--clr-light-grey);
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.width-80 {
  width: 80%;
}

.gradient-gray {
  background: var(--background-image);
  position: relative;
}

/* Sectionn Heading Left Start*/

.title-section-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-section-left .header-section-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.title-section-left .header-small-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.title-section-left .header-small-title .icon-div {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-green);
  border-radius: 50%;
}

.title-section-left .header-small-title .icon-div i {
  font-size: var(--h3-font-size);
  color: var(--clr-white);
}

.title-section-left .header-small-title p {
  letter-spacing: -0.5px !important;
  color: var(--clr-medium-grey);
  font-weight: var(--font-weight-500);
  font-size: 19px;
}

.title-section-left .header-large-title {
  font-weight: var(--font-weight-600);
  letter-spacing: -3px;
  line-height: 3.5rem;
  font-size: var(--h1-font-size);
}

.title-section-left .header-paragraph {
  font-size: var(--normal-font-size);
}

/* Sectionn Heading Left End*/

/* Section Bottom Area Start */

.section-bottom-area {
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-bottom-area p {
  text-align: center;
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-400);
}

.section-bottom-area p a {
  text-decoration: underline;
  font-weight: var(--font-weight-700);
}

/* Section Bottom Area End */

/* miscs */

.middle-text {
  text-align: center;
}

.w-full {
  width: 100%;
}

/* HTML: <span class="loader"></span> */
.loader {
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: -1rem;
}

.input-error {
  border-color: red !important;
}

.Toastify__toast-container {
  z-index: 1000000 !important;
}

.Toastify {
  z-index: 1000000 !important;
}

.text-center {
  text-align: center;
}

.Toastify__toast-theme--light {
  background-color: var(--clr-white) !important;
  color: var(--clr-dark-blue) !important;
}

.mt {
  margin-top: 1rem !important;
  text-align: center;
}













/* Hero end */
.hero-section {
  height: 100vh;
  position: relative;
  background-color: var(--clr-dark-blue);
  z-index: 0;
  overflow: hidden;
}

.biggest-title {
  position: absolute;
  font-size: 15vw;
  opacity: 0.3;
  color: var(--clr-green);
  transform: translate(20%, 20%);
  background-image: url("https://jennetteproperties.com/wp-content/uploads/2023/03/orange-perforated-pickleball-many-balls-abstract-background-generative-ai-2048x1550.jpg");
  background-size: cover;
  background-position: center;
  background-clip: text;
  -webkit-background-clip: text;
  mask-repeat: no-repeat;
  line-height: normal;
}

.vertical-lines {
  /* background-image: url("../images/vertical-line-bg-small.svg"); */
  fill: black;
  stroke: black;
  background-position: center top;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: -1;
}

.hero-container {
  /* margin-top: 5%; */
  z-index: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  color: var(--clr-dark-blue);
}

.hero-content {
  margin-top: 10%;
  flex: 40%;
  padding: 20px;
  z-index: 2;
}

.hero-title {
  font-size: var(--big-font-size);
  margin-bottom: 20px;
  color: var(--clr-white);
  line-height: 100%;

  letter-spacing: -3px;
  /* line-height: 3.5rem; */
}

.hero-subtitle {
  font-size: var(--normal-font-size);
  margin-bottom: 40px;
  color: var(--clr-white);
}

.hero-image {
  position: relative;
  flex: 60%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* z-index: -1; */
}

.hero-image .Hero-shape1 {
  position: absolute;
  right: -10%;
  top: -5%;
}

.Hero-shape2 {
  position: absolute;
  left: -5%;
  top: 20%;
  /* z-index: -1; */
}

.mouse-button {
  position: absolute;
  right: 50%;
  top: 0rem;
  transform: translate(50%, -50%);
  height: 70px;
  width: 70px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08);
  background-color: var(--clr-white);
  padding: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* z-index: 100; */
}

.mouse-button i {
  font-size: 1.7rem;
}

.hero-image img {
  max-width: 700px;
}

/* Hero end */
/* Bread Crumbs Start*/

.bread-crumbs-section {
  height: 40vh;
  position: relative;
  background-color: var(--clr-dark-blue);
  overflow: hidden;
  z-index: 0;
}

#bookCourt .bread-crumbs-section {
  height: 5vh !important;
}

#bookCourt .bread-crumbs-section img,
#bookCourt .bread-crumbs-section .particle-container {
  display: none;
}

#error404 .bread-crumbs-section {
  height: 3vh !important;
}

#error404 .bread-crumbs-section img,
#error404 .bread-crumbs-section .particle-container {
  display: none;
}

.bread-crumbs-content {
  margin-top: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.text-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 50%;
}

.bread-crumb-img {
  flex: 50%;
}

.bread-crumb-img img {
  margin-top: -10rem;
}

.breadcrumb {
  margin-bottom: 10px;
}

.breadcrumb ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.breadcrumb li {
  font-size: var(--normal-font-size);
  margin-right: 10px;
  color: var(--clr-green);
}

.breadcrumb li:not(:last-child)::after {
  content: "/";
  margin-left: 10px;
}

.breadcrumb a {
  text-decoration: none;
  color: var(--clr-green);
}

.breadcrumb a:hover {
  text-decoration: underline;
}

.content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: var(--clr-white);
  letter-spacing: -3px;
  line-height: 3.5rem;
}

.content p {
  font-size: var(--normal-font-size);
  line-height: 1.5;
  color: var(--clr-white);
}

/* Bread Crumbs End*/

/* contat us STARt*/
.contact-form-container .shadow-card {
  margin-right: 5rem;
  margin-left: 5rem;
}

.contact-us-map-container {
  position: relative;
  box-shadow: var(--shadow);
  padding: 0 !important;
  border-radius: 1rem;
}

.contact-us-map-container iframe {
  height: 70vh;
  width: 100%;
  border-radius: 1rem;
  border: none;
}

.contact-us-map-container .contact-us-map-content-box {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 45%;
}

.map-content-text {
  background: var(--clr-white);
  padding: 13%;
  border-radius: 6px 0 6px 0;
  /* background-image: url("../images/shapes/contact-us-half-circle.png"); */
  background-repeat: no-repeat;
  background-position: bottom right;
  border-bottom-right-radius: 1rem;
}

.map-content-text .btn-primary {
  margin-top: 35px;
}

.map-content-text h6 {
  font-weight: var(--font-weight-600);
  margin-bottom: 1rem;
  letter-spacing: -1px;
  color: var(--clr-dark-blue);
  font-family: var(--clr-light-grey);
  font-size: 1.75rem;
  line-height: 1.95rem;
}

.map-content-text p {
  margin-bottom: 1rem;
  font-size: var(--normal-font-size);
}

.map-content-text .contact-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}

.map-content-text .contact-info a {
  font-size: var(--normal-font-size);
  color: var(--clr-medium-grey);
}

.map-content-text .contact-info a span {
  color: var(--clr-dark-blue);
  font-weight: var(--font-weight-700);
}

.contact-us-form-50-50 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.contact-us-form-50-50 .contact-note {
  margin-bottom: 1rem;
  line-height: 28px;
  max-width: 50%;
}

/* contact us END*/
/* About us Start*/
.about-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.about-div .about-left {
  width: 60%;
}

.about-div .about-right {
  width: 40%;
}

/* About us End*/

/* Book A Court Start*/
.sports-type-selection-container {}

.sports-type-list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
  margin-top: 2rem;
}

.sports-type-list .sports-type-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: var(--clr-white);
  border-radius: 15px;
  border: 0.5px solid var(--clr-light-grey);
  transition: opacity 0.6s ease-in-out;
}

.sports-type-list .sports-type-item:hover {
  /* background-image: url("../images/shapes/white-right-circle.png"); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  opacity: 1;
}

.sports-type-list .sports-type-item.active {
  /* background-image: url("../images/shapes/white-right-circle.png"); */
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  opacity: 1;
  box-shadow: var(--shadow);
  border: 0.5px solid var(--clr-green);
}

.sports-type-list .sports-type-item h3 {
  font-weight: var(--font-weight-600);
  color: var(--clr-dark-blue);
  line-height: 30px;
}

.sports-type-list .sports-type-item .icon-div img {
  height: 4rem;
  width: auto;
}

.venue-selector-container {
  margin-top: 2rem;
}

.venue-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
}

.venue-cards .venue-shadow-card {
  margin: 1rem;
  padding: 14px;
  padding-bottom: 1rem;
  background: var(--clr-white);
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border-radius: 40px;
  transition: transform 0.2s;
}

.venue-cards .venue-shadow-card:hover {
  -webkit-transform: translate3d(0, -3px, 0);
  transform: translate3d(0, -3px, 0);
}

.venue-cards .venue-shadow-card .venue-info-content {
  padding-left: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  padding-top: 20px;
}

.venue-cards .venue-shadow-card .venue-info-content .book-now-btn {
  color: var(--clr-green);
  font-size: var(--normal-font-size);
}

.venue-cards .venue-shadow-card .venue-info-content h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-600);
  color: var(--clr-dark-blue);
  line-height: 30px;
  margin-bottom: 0.5rem;
}

.venue-cards .venue-shadow-card .venue-info-content p {
  font-size: var(--normal-font-size);
  margin-bottom: 1rem;
}

.venue-cards .venue-shadow-card .image-div {
  height: 260px;
  max-height: 275px;
  overflow: hidden;
  border-radius: 30px;
}

.venue-cards .venue-shadow-card .image-div img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.slot-availability-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
  margin-top: 2rem;
}

.slot-info-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.slot-info-div h3 {
  line-height: 28px;
  font-size: var(--h3-font-size);
}

.slot-availability-container .slot-dates-container {
  width: 100%;
  overflow: hidden;

  position: relative;
  -webkit-mask-image: linear-gradient(to right,
      transparent 0,
      #000 0%,
      #000 98%,
      transparent 100%);
}

.slot-availability-container .slot-dates-container .slot-dates {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: fit-content;
  width: 100%;
  gap: 1rem;
}

.slot-availability-container .slot-dates-container .slot-date {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-white);
  border-radius: 15px;
  border: 0.5px solid var(--clr-light-grey);
  color: var(--clr-dark-blue);
  width: fit-content;
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 0 0 40px 40px var(--clr-white) inset;
}

.slot-availability-container .slot-dates-container .slot-date:hover {
  /* background-color: var(--clr-light-grey); */
  color: var(--clr-dark-blue);
  border-radius: 15px;
  box-shadow: 0 0 40px 40px var(--clr-light-grey) inset;
  transition: box-shadow 300ms ease-in-out;
}

.slot-availability-container .slot-dates-container .slot-date.active {
  /* background-color: var(--clr-dark-blue); */
  color: var(--clr-white);
  border-radius: 15px;
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 0 0 40px 40px var(--clr-dark-blue) inset;
}

.slot-availability-container .slot-dates-container .slot-date.active:hover {
  /* background-color: var(--clr-dark-blue); */
  color: var(--clr-white);
  border-radius: 15px;
  box-shadow: 0 0 40px 40px var(--clr-dark-blue) inset;
  transition: box-shadow 300ms ease-in-out;
}

.slot-availability-container .slot-dates-container .slot-date h3 {
  line-height: 20px;
  font-size: var(--normal-font-size);
  padding: 1rem;
}

.prev-button,
.next-button {
  background-color: var(--clr-light-grey);
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin: 0 1rem;
  font-size: 1rem;
  border-radius: 5px;
}

.prev-button:hover,
.next-button:hover {
  background-color: var(--clr-dark-grey);
  color: var(--clr-white);
}

.slot-court-timings {
  width: 100%;
}

.slot-court-timings .slot-court-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.slot-court-timings .slot-court-list .slot-court-item {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid var(--clr-light-grey);
}

.slot-court-timings .slot-court-list .slot-court-item:last-child {
  border-bottom: none;
}

.slot-court-timings .slot-court-list .slot-court-item .court-title-area {
  width: 20%;
  max-width: 250px;
  position: relative;
}

.slot-court-timings .slot-court-list .slot-court-item .court-title-area .outlined-text {
  position: absolute;
  font-size: 8rem;
  z-index: -1;
  color: transparent;
  top: -25px;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: lightgray;
  line-height: 130px;
}

.slot-court-timings .slot-court-list .slot-court-item .court-time-area {
  width: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.slot-court-timings .slot-court-list .slot-court-item .court-title-area h3 {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-600);
  margin-top: 2rem;
}

.slot-court-timings .slot-court-list .slot-court-item .court-time-area .time-list {
  text-align: center;
  background-color: var(--clr-white);
  border-radius: 5px;
  border: 0.5px solid var(--clr-light-grey);
  color: var(--clr-dark-blue);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 10px;

  box-shadow: 0 0 40px 40px var(--clr-white) inset;
  transition: box-shadow 300ms ease-in-out;
}

.slot-court-timings .slot-court-list .slot-court-item .court-time-area .time-list :hover,
.slot-court-timings .slot-court-list .slot-court-item .court-time-area .time-list .time:hover {
  /* background-color: var(--clr-green); */
  /* color: var(--clr-white);
    border-radius: 10px;
    box-shadow: 0 0 40px 40px var(--clr-green) inset;
    transition: box-shadow 300ms ease-in-out; */
}

.slot-court-timings .slot-court-list .slot-court-item .court-time-area .time-list .time {
  padding: 1rem;
  line-height: 1rem;
  font-weight: var(--font-weight-700);
  background-color: var(--clr-white);
  border-radius: 10px;
  box-shadow: 0 0 40px 40px var(--clr-white) inset;
  transition: box-shadow 300ms ease-in-out;

  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  align-items: center;
  justify-content: center;
}

.slot-court-timings .slot-court-list .slot-court-item .court-time-area .time-list .time.selected {
  /* background-color: var(--clr-green); */
  color: var(--clr-white);
  border-radius: 10px;
  transition: box-shadow 300ms ease-in-out;
  box-shadow: 0 0 40px 40px var(--clr-green) inset;
}

.testimonials .bg-circle {
  left: 50%;
  transform: translate(0%, 0);
  position: absolute;
  z-index: 0;
}

.scroll {
  padding: 0 0 100vh;
}

.back-button {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 2rem;

  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-700);
  letter-spacing: -1.05px;
}

/* Book A Court End*/

/* Testimonials Start*/
.testimonials-list {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.testimonials-slider .testimonials-item {
  margin: 0rem 0.5rem 1rem 0.5rem;
  box-shadow: none;
  border: 1px solid var(--clr-light-grey);
}

.testimonials-item {
  background-color: var(--clr-white);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  padding: 10%;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -0.5rem !important;
}

.swiper-pagination-bullet-active {
  background: var(--clr-dark-blue) !important;
}

.testimonials-item .testimonial-user {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}

.testimonials-item .testimonial-user h3 {
  color: var(--clr-dark-blue);
}

.testimonials-item .testimonial-user p {
  font-size: var(--normal-font-size);
}

.testimonials-item .testimonial-user .img-div img {
  width: 90px;
  border-radius: 50%;
}

.testimonials-item .testimonial-user .testimonials-user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.testimonials-item .testimonial-user .testimonials-user-info h3 {
  color: var(--clr-dark-blue);
}

.testimonials-item .testimonial-user .testimonials-user-info p {
  font-size: var(--normal-font-size);
}

.testimonials-item .testimonial-review p {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-400);
}

.testimonials-item .testimonials-rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonials-item .testimonials-rating .star-ratings {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.testimonials-item .testimonials-rating .star-ratings p {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-600);
}

.testimonials-item .testimonials-rating .star-ratings .stars {
  display: flex;
  align-items: center;
}

.testimonials-item .testimonials-rating .star-ratings .stars i {
  font-size: 1.2rem;
  margin-bottom: 1px;
  color: var(--clr-orange);
}

.testimonials-item .testimonials-rating .rating-date {
  padding: 0.2rem 0.8rem;
  border-radius: 50px;
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
  font-size: var(--small-font-size);
  font-weight: var(--font-weight-600);
}

/* Home Slider Testimonials */
.home-testimonials-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-testimonials-container .title-section-left {
  width: 40%;
}

.home-testimonials-container .testimonials-slider {
  width: 60%;
}

/* Testimonials End*/

/* steps of booking Start*/

.home-steps {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.steps-of-booking {
  flex-wrap: wrap;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
}

.steps-of-booking .step-box {
  flex: 0 0 auto;
  width: 50%;
  align-self: flex-start;
  padding-right: 15px;
  padding-left: 15px;
}

.steps-of-booking .step-box:nth-child(2) {
  margin-top: 25%;
}

.steps-of-booking .step-box:nth-child(3) {
  margin-top: -5%;
}

.steps-of-booking .step-box .step-box-content {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: start;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  padding-left: 30px;
  padding-right: 30px;
  text-align: left;
}

.steps-of-booking .step-box .step-box-content h3 {
  padding-top: 20%;
  font-weight: var(--font-weight-600);
  margin-bottom: 5px;
  font-size: var(--h3-font-size);
  color: var(--clr-dark-blue);
}

.steps-of-booking .step-box .step-box-content p {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-300);
  color: var(--clr-medium-grey);
  line-height: 30px;
}

.step-box-bottom-line {
  margin-top: 10px;
  background-color: var(--clr-orange);
  height: 3px;
  width: 60px;
  background-color: var(--clr-orange);
  display: inline-block;
}

.steps-of-booking .step-box .step-box-content .step-box-title-number {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  letter-spacing: -1px;
  font-weight: var(--font-weight-700);
  font-size: 6.25rem;
  line-height: 6.25rem;
}

/* steps of booking End*/

/* marquee section Start*/

.marquee-section .marquee-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-mask-image: linear-gradient(to right,
      transparent 0,
      #000 20%,
      #000 80%,
      transparent 100%);
}

.marquee-section .marquee-content .marquee-dark-title {
  position: absolute;
  z-index: 11;
  font-size: 2.813rem;
  font-weight: var(--font-weight-600);
  letter-spacing: -2px;
}

/* marquee section End*/

/* marquee section End*/
.about-us-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.about-us-section .image-section {
  position: relative;
  width: 55%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.about-us-section .image-section .centered-img {
  -webkit-filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 20px 40px rgba(0, 0, 0, 0.2));
}

.about-us-section .image-section .shape1 {
  position: absolute;
  right: 0;
  bottom: 0;
  max-height: 50%;
}

.about-us-section .image-section .shape2 {
  position: absolute;
  left: -2rem;
  top: -5rem;
}

.about-us-section .title-section-left {
  width: 45%;
}

/* Brands section Start */

.brands-list {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;
}

.brands-list .brand {
  padding: 1rem;
}

/* Brands section end */

/* Pricing section Start */
.membership__container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* PillTabs.css */
.pill-tabs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  transition: 0.3s;
  width: 100%;
}

.pill-tabs-container h1 {
  color: #121212;
  text-align: center;
  font-size: 2.8125rem;
  font-style: normal;
  font-weight: var(--font-weight-700);
  line-height: 3.0625rem;
  /* 108.889% */
  letter-spacing: -0.07569rem;
}

.pill-tabs {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  transition: 0.3s;
}

.pill-tab {
  display: flex;
  width: 10.5rem;
  height: 8rem;
  padding: 0.75rem 1.5rem 1rem 1.5rem;
  flex-direction: column;
  align-items: center;
  gap: 0.4375rem;
  /* flex-shrink: 0; */
  border-radius: 1rem;
  border: 2px solid var(--clr-light-grey);
  background: #f6f5f4;
  transition: 0.3s;
  cursor: pointer;
}

.pill-tab p {
  color: #121212;
  text-align: center;
  font-size: var(--normal-font-size);
  font-style: normal;
  font-weight: var(--font-weight-500);
  line-height: 1.4375rem;
  /* 143.75% */
  letter-spacing: -0.0015rem;
}

.pill-tab img {
  width: 2.375rem;
  height: 2.375rem;
  min-width: 2.375rem;
  max-width: 2.375rem;
  min-height: 2.375rem;
  max-height: 2.375rem;
  transition: 0.3s;
}

.pill-tab.active {
  border-radius: 1rem;
  border: 2px solid var(--clr-dark-blue);
  box-shadow: var(--shadow);
  background-color: white;
  /* background-image: url("../images/shapes/section-half-circle.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  transition: 0.3s;
}

.pricing-contents {
  width: 100%;
}

.pricing-contents .pricing-card-list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.pricing-card-list {
  opacity: 1;
  position: relative;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  transform: translateY(0);
}

.pricing-card-list.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.pricing-card-list.fade-out {
  opacity: 0;
  transform: translateY(-20px);
  /* Adjust the distance as needed */
}

.pricing-contents .pricing-card-list .pricing-card {
  -webkit-box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  box-shadow: 0 20px 60px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border-radius: 6px;
  background-color: var(--clr-white);
  border: none;
  padding-top: 35px;
  padding-bottom: 35px;
  width: 100%;
  max-width: 350px;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-header {
  padding-left: 18%;
  padding-right: 18%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-header .validity-badge {
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border: 1px solid;
  text-align: center;
  border-color: #e4e4e4;
  border-radius: 100px;
  margin-bottom: 30px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: var(--font-weight-600);
  color: var(--clr-dark-blue);
  width: fit-content;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-header h2 {
  letter-spacing: -3px;
  font-weight: var(--font-weight-600);
  font-size: 2.5rem;
  line-height: 2.5rem;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-header h2 sup {
  font-size: 1.875rem;
  line-height: 2.8rem;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-header small {
  margin-bottom: 25px;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-features {
  padding-top: 15px;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-features ul li {
  border-bottom: 1px solid;
  border-color: rgba(35, 35, 35, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.pricing-contents .pricing-card-list .pricing-card .pricing-features ul li:last-child {
  border-bottom: 0;
}

/* Pricing section end */

.view-more-btn-div {
  padding: 3rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Events section Start */
.events-list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  flex-wrap: wrap;
}

.events-list .event-card {
  border-color: var(--clr-light-grey);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border-radius: 6px;
  overflow: hidden;
}

.events-list .event-card .event-header {
  overflow: hidden;
  height: 275px;
  position: relative;
}

.events-list .event-card .event-header .overlay-date {
  position: absolute;
  top: 1rem;
  left: 1rem;
  background-color: var(--clr-green);
  color: var(--clr-white);
  padding: 0.5rem;
  border-radius: 15px;
  box-shadow: var(--shadow);
  cursor: pointer;
}

.events-list .event-card:hover .event-header .overlay-date {
  background-color: var(--clr-dark-blue);
}

.events-list .event-card .event-header .overlay-date .date-texts {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
}

.events-list .event-card .event-header .overlay-date .date-texts h2 {
  color: var(--clr-white);
  font-weight: var(--font-weight-700);
  font-size: var(--normal-font-size);
}

.events-list .event-card .event-header .overlay-date .date-texts p {
  font-size: 14px;
  font-weight: var(--font-weight-500);
  color: var(--clr-white);
}

.events-list .event-card .event-header .overlay-date .date-texts h2 sub {
  font-size: var(--h2-font-size);
}

.events-list .event-card .event-header .event-image a img {
  /* overflow: hidden; */
  border-radius: 10px 10px 0 0;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.events-list .event-card:hover .event-header .event-image a img {
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  transform: scale(1.1) rotate(0deg);
  -webkit-transform: scale(1.1) rotate(0deg);
  -ms-transform: scale(1.1) rotate(0deg);
}

.events-list .event-card .event-content {
  padding-left: 35px;
  padding-bottom: 30px;
  padding-right: 35px;
  padding-top: 30px;
}

.events-list .event-card .event-content .location-timings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.events-list .event-card .event-content .location-timings .timings,
.events-list .event-card .event-content .location-timings .Location {
  font-size: 14px;
  font-weight: var(--font-weight-300);
  color: var(--clr-dark-blue);
}

.events-list .event-card .event-content .event-title h1 {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-700);
}

/* Events section end */

/* Tournament section Start */

.testimonials .title-section-left {
  margin-bottom: 3rem;
}

.tournament-list {
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  flex-wrap: wrap;
}

.tournament-list .tournament-card {
  border-color: var(--clr-light-grey);
  -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  -webkit-transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border-radius: 6px;
  overflow: hidden;
  background: var(--clr-white);
}

.tournament-list .tournament-card .tournament-header {
  overflow: hidden;
  height: 275px;
  position: relative;
}

.tournament-list .tournament-card .tournament-content {
  position: relative;
}

.tournament-list .tournament-card .tournament-content .overlay-price {
  position: absolute;
  top: -3.5rem;
  right: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--clr-green);
  padding: 0.5rem;
  border-radius: 50%;
  box-shadow: var(--shadow);
  cursor: pointer;
  padding: 2rem 0.8rem;
  color: white;
}

.tournament-list .tournament-card:hover .overlay-price {
  background-color: var(--clr-dark-blue);
}

.tournament-list .tournament-card .overlay-price .price-text {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
}

.tournament-list .tournament-card .overlay-price .price-text h2 {
  color: var(--clr-white);
  font-weight: var(--font-weight-700);
  font-size: var(--normal-font-size);
}

.tournament-list .tournament-card .tournament-header .overlay-date .date-texts p {
  font-size: 14px;
  font-weight: var(--font-weight-500);
  color: var(--clr-white);
}

.tournament-list .tournament-card .tournament-header .overlay-price .price-texts h2 {
  font-size: var(--h2-font-size);
}

.tournament-list .tournament-card .tournament-header .tournament-image a img {
  /* overflow: hidden; */
  border-radius: 10px 10px 0 0;
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.tournament-list .tournament-card:hover .tournament-header .tournament-image a img {
  -webkit-transition: ease all 0.5s;
  -ms-transition: ease all 0.5s;
  transition: ease all 0.5s;
  transform: scale(1.1) rotate(0deg);
  -webkit-transform: scale(1.1) rotate(0deg);
  -ms-transform: scale(1.1) rotate(0deg);
}

.tournament-list .tournament-card .tournament-content {
  padding-left: 35px;
  padding-bottom: 30px;
  padding-right: 35px;
  padding-top: 30px;
}

.tournament-list .tournament-card .tournament-content .timings .date-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.tournament-list .tournament-card .tournament-content .timings .date-time .date::after {
  content: "•";
  display: inline-block;
  font-family: bootstrap-icons;
  vertical-align: middle;
  margin: -2px 2px 0;
  font-size: 22px;
  color: var(--medium-gray);
  opacity: 0.7;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.tournament-list .tournament-card .tournament-content .timings .date-time .date,
.tournament-list .tournament-card .tournament-content .timings .date-time .time {
  font-size: 16px;
  font-weight: var(--font-weight-400);
  color: var(--clr-dark-blue);
}

.tournament-list .tournament-card .tournament-content .tournament-title h1 {
  font-size: var(--h3-font-size);
  font-weight: var(--font-weight-700);
  margin-bottom: 0.5rem;

  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tournament-card .tournament-footer {
  border-top: 1px solid var(--clr-light-grey);
  padding-left: 35px;
  padding-bottom: 20px;
  padding-right: 35px;
  padding-top: 15px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tournament-card .tournament-footer .applicants,
.tournament-card .tournament-footer .days-left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

/* Tournament section end */
/* terms conditions section Start */
.terms-conditions-info-container {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.terms-conditions-info-container .header-intro-ts {
  text-align: center;
  margin-bottom: 3rem;
}

.terms-conditions-info-container .header-intro-ts p {
  max-width: 60%;
  margin-left: auto;
  margin-right: auto;
}

.terms-conditions-info-container .terms-title-large {
  font-size: var(--h2-font-size);
  font-weight: var(--font-weight-600);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.terms-conditions-info-container .terms-conditions-right-sided {
  margin-left: 3rem;
}

.terms-conditions-info-container .terms-conditions-right-sided-inner {
  margin-left: 3rem;
}

.terms-conditions-info-container .terms-paragraphs {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-400);
  margin-top: 1rem;
  margin-bottom: 1rem;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.terms-conditions-info-container .terms-paragraphs .bold {
  font-weight: var(--font-weight-500);
  margin-right: 0.5rem;
}

.terms-conditions-info-container .terms-paragraphs .green {
  color: var(--clr-green);
}

.terms-conditions-info-container .terms-paragraphs i {
  margin-right: 1rem;
  font-size: 20px;
  font-weight: var(--font-weight-400);
  color: var(--clr-green);
}

.terms-conditions-info-container .terms-title-small {
  font-size: var(--h3-font-size);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* terms conditions section End */

/* Info container Start */
.redirect-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  margin: 0px auto;
}

.redirect-info-header {
  font-size: 2em;
  margin-bottom: 20px;
  font-weight: var(--font-weight-600);
  letter-spacing: -3px;
  line-height: 3.5rem;
}

.redirect-info-message {
  font-size: var(--normal-font-size);
  margin-bottom: 20px;
  font-weight: var(--font-weight-400);
  color: var(--clr-dark-blue);
}

.redirect-info-contactInfo {
  margin-bottom: 20px;
}

.redirect-info-contactInfo a {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-600);
  color: var(--clr-green);
}

/* Info container End */
/* 404 Not Found Start */
.error-section-404 {
  height: 100vh;
  overflow: hidden;
}

.error-section-404 {
  height: 80vh;
  margin-top: 2rem;
  margin-bottom: 0rem;
}

.container-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container-404 img {
  height: 100%;
  width: auto;
  max-height: 55vh;
}

.container-404 h1 {
  font-size: var(--h2-font-size);
  margin-bottom: 20px;
  color: var(--clr-dark-blue);
  line-height: 100%;
  letter-spacing: -1px;
}

/* 404 Not Found End */
/* Why Pickleball Start */
.why-pickleball-list {
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem auto;
  max-width: 80%;
  gap: 1.5rem;
}

.why-pickleball-list .why-pickleball {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: -0.5rem;
  box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
}

.why-pickleball-list .why-pickleball:nth-child(even) {
  transform: rotate(2deg);
}

.why-pickleball-list .why-pickleball:nth-child(odd) {
  transform: rotate(-2deg);
}

.why-pickleball-list .why-pickleball .image-div {
  width: 20%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.why-pickleball-list .why-pickleball .image-div img {
  max-width: 60%;
}

.why-pickleball-list .why-pickleball .content-div {
  width: 70%;
}

/* Why Pickleball End */

/* Slot Booking Start */
.book-slot-container {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.75rem;
}

.book-slot-container .slot-time-table {
  width: 70%;
}

.book-slot-container .slot-booking-user-div {
  width: 30%;
  display: block;
}

.slot-mobile-button {
  display: none;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 5.5rem;
}

.selected-slots-lists {
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selected-slots-lists .selected-slot {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  margin: 1rem 0rem;
  background-color: var(--clr-light-grey);
  height: 1px;
}

.selected-slots-lists .selected-slot .selected-slot-time-date-div .selected-slot-date {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-600);
  line-height: 1rem;
}

.selected-slots-lists .selected-slot .selected-slot-time-date-div .selected-slot-time {
  font-size: var(--normal-font-size);
  opacity: 0.8;
  color: var(--clr-medium-grey);
}

.selected-slots-lists .selected-slot .selected-slot-price .slot-price {
  font-size: var(--normal-font-size);
}

.summery-content {
  padding: 20px;
  border: 1px solid var(--clr-dark-blue);
  border-radius: 10px;
}

.summery-content label {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-600);
}

.summery-content .prices-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.summery-content p {
  font-size: var(--normal-font-size);
}

.overview-area {
  padding: 10px 0px;
}

.overview-area button {
  width: 100%;
}

/* Slot Booking End */

#profile .bread-crumbs-section,
#booking-history .bread-crumbs-section {
  height: 5vh !important;
}

#profile .bread-crumbs-section .bread-crumb-img,
#profile .bread-crumbs-section .text-content .content,
#booking-history .bread-crumbs-section .bread-crumb-img,
#booking-history .bread-crumbs-section .text-content .content {
  display: none;
}

#profile_settings .profile-form-container {
  margin-top: 2rem;
}

.profile-details-container,
.profile-form-container {
  margin-bottom: 2rem;
}

.profile-settings-cards {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.profile-settings-cards .profile-options-card {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.profile-settings-cards .profile-options-card.active {
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
}

.profile-settings-cards .profile-options-card i {
  font-size: 1.5rem;
}

.profile-settings-cards .profile-options-card p {
  font-size: var(--normal-font-size);
}

#profile_settings .profile-form {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
}

.profile-split-flex .profile-image .avatar-upload {
  margin: 0 auto;
}

.profile-split-flex .profile-image .avatar-upload .avatar-edit {
  right: 0;
}

#profile_settings .profile-333333-fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 1rem;
  width: 100%;
}

#profile_settings .profile-5050-fields {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: start;
  gap: 1rem;
  width: 100%;
}

#profile_settings .profile-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.profile-details-container,
.profile-form-container {
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.show {
  opacity: 1;
  visibility: visible;
}

.profile-details-container {
  margin-top: 2rem;
}

.profile-details-container .personal-details-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-details-container .personal-details-card .info-contents p {
  color: var(--clr-dark-blue);
  font-size: var(--normal-font-size);
}

.profile-details-container .title-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
}

.profile-details-container .title-area .edit-button {
  background-color: rgb(255, 255, 255);
  /* border: 1px solid rgb(226, 226, 226); */
  padding: 0.3rem 0.4rem;
  border-radius: 50%;
  box-shadow: 0px 0px 10px lightgray;
}

.profile-details-container .title-area .edit-button:hover {
  color: var(--clr-green);
}

.profile-details-container .profile-split-flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
}

.profile-details-container .profile-img {
  height: 7rem;
  width: 7rem;
  border: 0.5rem solid rgb(219, 219, 219);
  border-radius: 50%;
  object-fit: cover;
}

.profile-details-container,
.profile-form-container {
  transition: opacity 0.5s ease, visibility 0.5s ease;
  opacity: 1;
  visibility: visible;
}

.profile-details-container.hidden,
.profile-form-container.hidden {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.membership-card {
  height: 100%;
  position: relative;

  background: #f3f8f8;
  border: 1px solid #d7d7d7;

  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 14px;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.membership-card .membership-card-bg {
  position: absolute;
  top: 0;
  left: 0;
  border: none;
  height: 3rem;
  opacity: 0.2;
}

.membership-card .profile-membership-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  height: 100%;
  width: 100%;
  padding: 24px;
  z-index: 12;
}

.membership-card .profile-membership-card .membership-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.membership-card .profile-membership-card .membership-flex .membership-title,
.membership-card .profile-membership-card .membership-flex .validity-hours,
.membership-card .profile-membership-card .membership-flex .purchase-date {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.membership-card .profile-membership-card .membership-flex .membership-timings,
.membership-card .profile-membership-card .membership-flex .validity-hours {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.membership-card .profile-membership-card .membership-flex h3 {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-700);
  letter-spacing: -1.05px;
}

.membership-card .profile-membership-card .membership-flex .membership-title .title,
.membership-card .profile-membership-card .membership-flex .membership-timings .title,
.membership-card .profile-membership-card .membership-flex .validity-date .date-title,
.membership-card .profile-membership-card .membership-flex .validity-hours .hours-title,
.membership-card .profile-membership-card .membership-flex .purchase-date .date-title {
  color: #121212;
  font-size: var(--normal-font-size);
  font-style: normal;
  font-weight: 600;
  /* line-height: 1.4375rem; */
  letter-spacing: -0.0015rem;
}

.membership-card .profile-membership-card .membership-flex .validity-date .date-title {
  text-align: unset;
}

.membership-card .profile-membership-card .membership-flex .validity-hours .hours-title {
  text-align: right;
}

.membership-card .profile-membership-card .membership-flex .membership-title .hours-tag {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border: 1px solid;
  text-align: center;
  border-color: #e4e4e4;
  border-radius: 100px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: var(--font-weight-600);
  color: var(--clr-dark-blue);
  width: -moz-fit-content;
  width: fit-content;
}

.bg {
  position: absolute;
  top: 5px;
  left: 5.50px;
  width: 98.6%;
  height: 96.7%;
  z-index: 2;
  background-color: white;
  /* background-image: url("../images/logo/logo-light.png"); */
  background-position: center;
  background-size: 40%;
  background-repeat: no-repeat;
  backdrop-filter: blur(24px);
  border-radius: 10px;
  overflow: hidden;
  outline: 2px solid white;
}

.blob {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: var(--clr-green);
  opacity: 1;
  filter: blur(12px);
  animation: blob-bounce 5s infinite ease;
}

@keyframes blob-bounce {
  0% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
    background-color: var(--clr-green);
  }

  25% {
    transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    background-color: var(--clr-green);
  }

  50% {
    transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    background-color: #000;
  }

  75% {
    transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    background-color: var(--clr-dark-blue);
  }

  100% {
    transform: translate(-100%, -100%) translate3d(0, 0, 0);
    background-color: var(--clr-dark-blue);
  }
}

.change-pass-card {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

.display-court-info-image {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.display-court-info-image img {
  width: 3.5rem;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

.responsive-table {
  position: relative;
  width: 100%;
  margin: 0;
  overflow-y: hidden;
  display: block;
}

/* Optional: Adjust table header and cell styles for better appearance */
.responsive-table .rdt_TableHead,
.responsive-table .rdt_TableHeadRow,
.responsive-table .rdt_TableHeader,
.responsive-table .rdt_TableHeaderCell {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.display-court-info-image .img {
  width: 40%;
}

.display-court-info-image .text-info {
  width: 60%;
}

.invoice-btn {
  color: var(--clr-green);
  font-size: 16px;
  font-weight: var(--font-weight-400);
  letter-spacing: -0.6px;
}

.display-court-info-image .text-info {
  display: flex;
  flex-direction: column;
  /* gap: .2rem; */
  justify-content: center;
  align-items: flex-start;
}

.date-time-div {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  justify-content: center;
  align-items: flex-start;
}

.display-court-info-image .text-info h3 {
  font-size: 18px;
  font-weight: var(--font-weight-700);
  letter-spacing: -0.6px;
  color: var(--clr-dark-blue);
}

.display-court-info-image .text-info h4 {
  font-size: 16px;
  font-weight: var(--font-weight-600);
  letter-spacing: -0.6px;
  color: var(--clr-dark-blue);
}

.date-time-div h4 {
  font-size: 16px;
  font-weight: var(--font-weight-600);
  letter-spacing: -0.6px;
  color: var(--clr-dark-blue);
}

.display-court-info-image .text-info p {
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-700);
  letter-spacing: -0.6px;
  color: var(--clr-dark-blue);
}

.history-tables {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.history-tables .title {
  font-size: var(--h2-font-size);
  letter-spacing: -0.6px;
  color: var(--clr-dark-blue);
}

.history-tables .actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;
  margin-bottom: 2rem;
}

.options-dropbtn {
  background-color: #f8f8f8;
  box-shadow: 0px 0px 10px lightgray;
  border-radius: 50%;
  color: white;
  padding: 8px 0px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

.options-dropbtn i {
  margin: 8px 10px;
  color: var(--clr-dark-blue);
  pointer-events: none;
}

/* The container <div> - needed to position the dropdown content */
.options-dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.options-dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
}

/* Links inside the dropdown */
.options-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.options-dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu (use JS to add this class when the user clicks on the dropdown button) */
.show {
  display: block;
}

.checkout-page-heading .back-book-court {
  padding: 0.5rem;
  border: 1px solid var(--clr-dark-blue);
  border-radius: 20px;
  font-size: var(--normal-font-size);
  font-weight: var(--font-weight-600);
  letter-spacing: -1px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.checkout-page-heading .heading-text {
  font-weight: var(--font-weight-600);
  letter-spacing: -1px;
  font-size: var(--h4-font-size);
  line-height: 1.95rem;
  margin: 1rem 0;
}

.checkout-page-information {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.checkout-page-information .checkout-page-info-div {
  width: 70%;
}

.checkout-page-information .checkout-page-summery {
  width: 30%;
}

.checkout-page-information .checkout-page-summery ul {
  margin-bottom: 1rem;
}

.checkout-page-information .checkout-page-summery ul li i {
  color: var(--clr-green);
}

.checkout-page-information .checkout-page-summery .product_collapsible-list {
  margin-top: 1rem;
}

.checkout-page-information .checkout-page-summery .total-info {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.checkout-page-information .checkout-page-summery .total-info-slots-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.checkout-page-information .checkout-page-summery .final-total {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  background-color: var(--clr-light-grey);
  border-radius: 15px;
}

.checkout-page-information .checkout-page-summery .small-texts {
  text-align: center;
  line-height: 1.5;
}

.checkout-page-information .checkout-page-summery .pay-button {
  margin-top: 0.5rem;
}

.checkout-page-information .checkout-page-summery .pay-button .btn-pay {
  width: 100%;
  background-color: var(--clr-green);
  font-weight: var(--font-weight-600);
  letter-spacing: 1px;
}

.checkout-page-information .checkout-page-summery .total-info-slots-div p {
  font-size: var(--normal-font-size);
  letter-spacing: -1px;
}

.checkout-page-information .checkout-page-summery .total-info-slots-div p b {
  font-size: var(--h4-font-size);
}

.checkout-page-individual {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selected-item-card .heading,
.checkout-billing-info .heading {
  text-align: left;
  margin-bottom: 2rem;
}

.selected-item-card .heading h3,
.checkout-billing-info .heading h3 {
  font-size: var(--h3-font-size);
  line-height: 100%;
  letter-spacing: -1px;
}

.selected-item-card .selected-court-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.selected-item-card .selected-court-list .selected-court {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding-bottom: 0.5rem;
}

.selected-item-card .selected-court-list .selected-court:last-child {
  border-bottom: none;
}

/* .selected-item-card .selected-court-list .selected-court .court-info-div {
    width: 50%;
} */

.selected-item-card .selected-court-list .selected-court .court-info-div .court-text-info {
  display: flex;
  flex-direction: column;
}

.selected-item-card .selected-court-list .selected-court .court-info-div .court-text-info h3 {
  font-weight: var(--font-weight-600);
  letter-spacing: -1px;
  font-size: var(--h3-font-size);
  line-height: 1.95rem;
}

.selected-item-card .selected-court-list .selected-court .court-info-div .court-text-info h4 {
  font-weight: var(--font-weight-600);
  letter-spacing: -1px;
  font-size: var(--h4-font-size);
  line-height: 1.95rem;
}

.selected-item-card .selected-court-list .selected-court .court-info-div .court-text-info p {
  font-weight: var(--font-weight-500);
  letter-spacing: -1px;
  font-size: var(--normal-font-size);
  line-height: 1.95rem;
}

.selected-item-card .selected-court-list .selected-court .selected-price {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sticky-bottom {
  z-index: 10;
}

.checkout-page-membership .selected-item-card {
  margin-bottom: 1rem;
}

.checkout_membership__container .checkout-membership-contents {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.checkout-membership {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.checkout-membership .checkout-membership-timings {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
}

.checkout-membership .checkout-membership-timings img {
  height: 3.4rem;
  width: auto;
  margin-bottom: 0.4rem;
}

.checkout-membership .checkout-membership-timings .timings-info {
  display: flex;
  flex-direction: column;
}

.checkout-membership .checkout-membership-timings .timings-info p {
  color: #121212;
  font-size: var(--normal-font-size);
  font-style: normal;
  font-weight: var(--font-weight-500);
  line-height: 1.4375rem;
  letter-spacing: -0.0015rem;
}

.checkout-membership .checkout-membership-plan-list {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans {
  cursor: pointer;
  position: relative;
  border: 1px solid var(--clr-dark-blue);
  padding: 1rem 1.5rem 1rem 1.5rem;
  border-radius: 15px;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans .validity-badge {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
  transition: all 350ms cubic-bezier(0.37, 0, 0.63, 1);
  border: 1px solid;
  text-align: center;
  border-color: #e4e4e4;
  border-radius: 100px;
  margin-bottom: 10px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 12px;
  font-weight: var(--font-weight-600);
  color: var(--clr-dark-blue);
  width: -moz-fit-content;
  width: fit-content;
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans .check-mark {
  position: absolute;
  top: 0.2rem;
  left: 0.5rem;
  font-size: 1.5rem;
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans .check-mark i {
  color: var(--clr-green);
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans h2 {
  font-weight: var(--font-weight-600);
}

.checkout-membership .checkout-membership-plan-list .checkout-membership-plans.active {
  background-color: white;
  /* background-image: url("../images/shapes/section-half-circle.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top right;
  transition: 0.3s;
  border: 2px solid;
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.25);
}

.checkout-form-container .not-logged-in {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.checkout-form-container .btn-primary {
  width: 100%;
}

.checkout-form-container .not-logged-in img {
  width: 80%;
  height: auto;
}

.checkout-form-container .not-logged-in p {
  text-align: center;
  font-size: var(--normal-font-size);
  letter-spacing: -1px;
}

.no-time-available-court-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.no-time-available-court-div img {
  width: 7rem;
  height: auto;
}

.no-time-available-court-div h3 {
  text-align: center;
  font-size: var(--normal-font-size);
  letter-spacing: -1px;
}

.checkout-area {
  margin-top: 2rem;
}

.slot-checkout-mobile-button {
  display: none;
}

@media (max-width: 768px) {
  .slot-checkout-mobile-button {
    display: flex;

  }
}