

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

:root {
  --header-height: 4rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/

  --clr-dark-blue: #012541;
  --clr-light-blue: #1695d1;
  --clr-green: #83b42c;
  --clr-dark-green: #4d871b;
  --clr-orange: #f6a834;
  --clr-white: #ffffff;
  --clr-light-grey: #eeeeee;
  --clr-medium-grey: #828c8a;
  --clr-dark-grey: #313e3b;
  --border: 1px solid var(--clr-light-grey);
  --shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  --background-image: linear-gradient(to bottom,
      #ffffff,
      #f7f9f9,
      #f3f6f6,
      #eff3f3,
      #ecf1f1);

  /*========== Font and typography ==========*/
  --body-font: "Space Grotesk", sans-serif;

  --big-font-size: 5rem;
  --h1-font-size: 3.87rem;
  --h2-font-size: 2rem;
  --h3-font-size: 1.5rem;
  --h4-font-size: 1.2rem;
  --normal-font-size: 1.15rem;
  --small-font-size: 0.813rem;

  /*========== Font weight ==========*/
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*========== Responsive typography ==========*/
@media (max-width: 1280px) {
  :root {
    --big-font-size: 4rem;
    --h1-font-size: 3.5rem;
    --h2-font-size: 2rem;
    --h3-font-size: 1.5rem;
    --h4-font-size: 1.2rem;
    --normal-font-size: 1.15rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 1020px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 3rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1.05rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 900px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 3rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1.15rem;
    --small-font-size: 0.813rem;
  }
}

@media (max-width: 475px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.8rem;
  }
}

@media (max-width: 390px) {
  :root {
    --big-font-size: 3rem;
    --h1-font-size: 2.5rem;
  }
}

/*=============== BASE ===============*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: "Inter", sans-serif; */
  font-family: "Space Grotesk", sans-serif;
}

html {
  scroll-behavior: smooth;
}

input,
button,
body {
  font-family: var(--body-font);
  line-height: 30px;
}

body {
  background-color: var(--clr-white);
  color: var(--clr-dark-blue);
  transition: background 0.4s;
  /* for dark mode animation */
  font-family: inherit;
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--clr-dark-blue);
  outline: 1px solid var(--clr-dark-blue);
  border-radius: 15px;
}

input,
button {
  border: none;
  outline: none;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-weight-700);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  color: inherit;
}

img {
  max-width: 100%;
  height: auto;
}

/*=============== REUSABLE CSS CLASSES ===============*/
.grid {
  display: grid;
  gap: 1.5rem;
}

.flex {
  display: flex;
  gap: 1.5rem;
}

.section {
  padding-block: 5rem;
}

.main {
  overflow: hidden;
  /* For animation ScrollReveal */
}

/* Global CSS */
.green {
  color: var(--clr-green);
}

.bold {
  font-weight: var(--font-weight-700);
}

.marquee-large-text {
  font-size: 10rem;
  color: var(--clr-light-grey);
  opacity: 0.7;
}

.btn {
  display: flex;
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
  text-align: center;
  padding: 0.8rem 1.4rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 35px;
  width: fit-content;
  justify-content: center;
}

.btn i {
  margin-left: 0.2rem;
}

.btn-primary {
  transition: 0.5s;
  background-color: var(--clr-dark-blue);
  color: var(--clr-white);
}

.btn-primary:hover {
  transition: 0.5s;
  background-color: var(--clr-green);
  color: var(--clr-white);
}

.btn-green {
  transition: 0.5s;
  background-color: var(--clr-green);
  color: var(--clr-white) !important;
}

.btn-green:hover {
  transition: 0.5s;
  background-color: var(--clr-white);
  color: var(--clr-dark-blue) !important;
}

.shadow-card {
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0px 4px 44px rgba(211, 211, 211, 0.25);
}

.section-title {
  padding-block: 3rem;
  text-align: center;
  font-size: 40px;
  position: relative;
  margin: 0 0 15px;
  z-index: 1;
}

.section-title span {
  font-size: inherit;
  background: linear-gradient(105.55deg,
      var(--clr-dark-blue) -6.68%,
      var(--clr-light-blue) 43.13%,
      var(--clr-green) 96.15%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-title span::after {
  content: "";
  position: absolute;
  background-image: url(https://img.freepik.com/premium-photo/facebook-template-3d-illustration_665513-4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
  left: 0;
  height: 70px;
  bottom: 0;
  z-index: -1;
  width: 100%;
  top: 15px;
  opacity: 0.1;
}

.separation-line {
  background-color: var(--clr-light-grey);
  height: 1px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.width-80 {
  width: 80%;
}

.gradient-gray {
  background: var(--background-image);
  position: relative;
}

/* Sectionn Heading Left Start*/

.title-section-left {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-section-left .header-section-titles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

.title-section-left .header-small-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
}

.title-section-left .header-small-title .icon-div {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--clr-green);
  border-radius: 50%;
}

.title-section-left .header-small-title .icon-div i {
  font-size: var(--h3-font-size);
  color: var(--clr-white);
}

.title-section-left .header-small-title p {
  letter-spacing: -0.5px !important;
  color: var(--clr-medium-grey);
  font-weight: var(--font-weight-500);
  font-size: 19px;
}

.title-section-left .header-large-title {
  font-weight: var(--font-weight-600);
  letter-spacing: -3px;
  line-height: 3.5rem;
  font-size: var(--h1-font-size);
}

.title-section-left .header-paragraph {
  font-size: var(--normal-font-size);
}

/* Sectionn Heading Left End*/

/* Section Bottom Area Start */

.section-bottom-area {
  margin-top: 2rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-bottom-area p {
  text-align: center;
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-400);
}

.section-bottom-area p a {
  text-decoration: underline;
  font-weight: var(--font-weight-700);
}

/* Section Bottom Area End */

/* miscs */

.middle-text {
  text-align: center;
}

.w-full {
  width: 100%;
}

/* HTML: <span class="loader"></span> */
.loader {
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: -1rem;
}

.input-error {
  border-color: red !important;
}

.Toastify__toast-container {
  z-index: 1000000 !important;
}

.Toastify {
  z-index: 1000000 !important;
}

.text-center {
  text-align: center;
}

.Toastify__toast-theme--light {
  background-color: var(--clr-white) !important;
  color: var(--clr-dark-blue) !important;
}

.mt {
  margin-top: 1rem !important;
  text-align: center;
}


.top-wrapper {
  display: flex;
  justify-content: center;
}